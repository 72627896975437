import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { AppBar, Grid, Skeleton, Toolbar } from '@mui/material';

// Custom Components
import UserMenu from 'components/userMenu/userMenu';
import { AccountCircleIcon, WhiteLogoIcon } from 'components/icons/icons';

// Contexts
import { UserInfoContext } from 'contexts/userInfoContext/userInfoContext';

// Text
import { PRODUCT_NAME, HEALTHWISE_LOGO_LINK_ARIA, APPLICATION_DASHBOARD_PATH } from 'utils/dictionary';

const StyledAppBar = styled(AppBar)`
  background: ${props => props.theme.colors.darkPrimary};
  color: ${props => props.theme.colors.white};
  box-shadow: none;
`;

// Style the Profile Component, padding takes into account the appbar 'gutter'
const StyledProfile = styled.div`
  background: ${props => props.theme.colors.primary};
  height: 100%;
  min-width: 180px;
  padding: 0px 0px 0px ${props => props.theme.spacingScale[3]};
`;

// Vertically centers the div in the parent container
const StyledProfileBlock = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

// Sets the height for the components in the AppBar; this is needed to contain the height of the
// sub-components
const AppGrid = styled(Grid)`
  height: 72px;
`;

// Set the boundries of the logo
const StyledLogo = styled(WhiteLogoIcon)`
  width: 160px;
  padding-left: ${props => props.theme.spacingScale[4]};
`;

const StyledAccountIcon = styled(AccountCircleIcon)`
  color: ${props => props.theme.colors.white};
  font-size: ${props => props.theme.typeScale[7]};
  vertical-align: middle;
  display: inline-flex;
  padding-right: 10px;
  padding-top: 5px;
`;

const StyledSkeleton = styled(Skeleton)`
  background-color: ${props => props.theme.colors.primaryTextOnDark};
  min-width: ${props => props.theme.typeScale[7]};
  display: inline-block;
`;

const StyledName = styled.span`
  font-size: ${props => props.theme.typeScale[3]};
  line-height: ${props => props.theme.spacingScale[2]};
`;

const StyledTool = styled.span`
  color: ${props => props.theme.colors.white};
  font-size: ${props => props.theme.typeScale[0]};
  font-style: italic;
`;

const StyledSkipLink = styled.a`
  width: 1px;
  height: 1px;
  overflow: hidden;
  &:focus {
    position: relative;
    width: auto;
    height: auto;
    top: 0;
    left: 0;
    padding: 0 4px;
    background-color: white;
    z-index: 100;
  }
`;

const Header = () => {
  let userInfo = useContext(UserInfoContext) ?? {};
  const { search } = useLocation();

  return (
    <>
      <StyledSkipLink
        aria-label="Select to skip navigation"
        href="#main"
        onClick={e => {
          e.preventDefault();
          const $main = document.querySelector('main');
          if ($main?.focus) {
            $main.focus();
          }
        }}
      >
        skip navigation
      </StyledSkipLink>
      <StyledAppBar position="absolute">
        <Toolbar disableGutters={true}>
          <Grid container direction="row" alignItems="center" justifyContent="space-between">
            <AppGrid item>
              <Link
                aria-label={HEALTHWISE_LOGO_LINK_ARIA}
                to={{
                  pathname: APPLICATION_DASHBOARD_PATH,
                  search: search
                }}
                alt="Healthwise - for every health decision."
              >
                <StyledLogo />
              </Link>
            </AppGrid>
            <AppGrid item>
              <StyledProfile>
                <StyledProfileBlock>
                  {userInfo?.status === 'success' ? (
                    <Grid container direction="row" alignItems="stretch">
                      <Grid item>
                        <StyledAccountIcon />
                      </Grid>
                      <Grid item>
                        <Grid container direction="column">
                          <Grid item>
                            <StyledName>
                              {userInfo.data?.data?.firstName ? (
                                userInfo.data?.data?.firstName
                              ) : (
                                <StyledSkeleton variant="text" />
                              )}
                            </StyledName>
                            <UserMenu user={userInfo.data?.data} />
                          </Grid>
                          <Grid item>
                            <StyledTool>{PRODUCT_NAME}</StyledTool>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : (
                    <></>
                  )}
                </StyledProfileBlock>
              </StyledProfile>
            </AppGrid>
          </Grid>
        </Toolbar>
      </StyledAppBar>
    </>
  );
};

export default Header;
